import React from "react";
import { Form, Table } from "react-bootstrap";

const StudyTable = ({ onMouseDown, tableRef }) => {
  return (
    <div
      className="overflow-auto p-2"
      style={{ height: "calc(100vh - 115px)" }}
    >
      <Table
        ref={tableRef}
        id="resizable-table"
        className="text-nowrap mb-1 custom-table"
        hover
        bordered
        striped
      >
        <thead className="position-sticky z-1" style={{ top: -1 }}>
          <tr>
            <th onMouseDown={(e) => onMouseDown(e, 1)} style={{ width: 40 }}>
              Sr.
            </th>
            <th onMouseDown={(e) => onMouseDown(e, 2)}>Study Id</th>
            <th onMouseDown={(e) => onMouseDown(e, 3)}>Sataus</th>
          </tr>
        </thead>
        <tbody>
          <tr className={`position-relative cursor-pointer `}>
            <td>1</td>
            <td>Study 01</td>
            <td className="p-0">
              <Form.Select className="rounded-0 bg-transparent border-0">
                <option value="">Active</option>
                <option value="">Inactive</option>
              </Form.Select>
            </td>
          </tr>
          <tr className={`position-relative cursor-pointer `}>
            <td>1</td>
            <td>Study 02</td>
            <td className="p-0">
              <Form.Select className="rounded-0 bg-transparent border-0">
                <option value="">Active</option>
                <option value="">Inactive</option>
              </Form.Select>
            </td>
          </tr>
          <tr className={`position-relative cursor-pointer `}>
            <td>1</td>
            <td>Study 03</td>
            <td className="p-0">
              <Form.Select className="rounded-0 bg-transparent border-0">
                <option value="">Active</option>
                <option value="">Inactive</option>
              </Form.Select>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default StudyTable;
