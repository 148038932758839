import React, { useEffect, useState } from "react";
import { Form, Offcanvas } from "react-bootstrap";
import { FaPlusSquare } from "react-icons/fa";
import { authService } from "../../../../APIServices/authService";

const AddUserForm = ({
  Show,
  Hide,
  Title,
  userUpdateId,
  getUserListByType,
}) => {
  const [userName, setUserName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [address, setAddress] = useState("");
  const [lastName, setLastName] = useState("");
  const [Country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [accessMgmt, setaccessMgmt] = useState([]);
  const [autority, setAutority] = useState("");

  const validatePhoneNumber = (phone) => {
    const phonePattern = /^\d{10}$/;

    if (phonePattern.test(phone)) {
      setPhoneError("");
    } else {
      setPhoneError("Phone Number should have 10 digits!");
    }
  };

  const accessmanagements = async () => {
    let data = await authService.accessManagements();
    setaccessMgmt(data);
  };

  useEffect(() => {
    accessmanagements();
  }, []);

  return (
    <div>
      <Offcanvas show={Show} onHide={Hide} placement="end">
        <Offcanvas.Header>
          <Offcanvas.Title>{Title}</Offcanvas.Title>
          <div onClick={Hide} className="close_modal" title="Close">
            X
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="hstack gap-2">
            <Form.Group className="mb-2 w-100">
              <Form.Label className="mb-1 fw-bold">
                User Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter User Name"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-2 w-100">
              <Form.Label className="mb-1 fw-bold">
                First Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter User Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Form.Group>
          </div>
          <div className="hstack gap-2">
            <Form.Group className="mb-2 w-100">
              <Form.Label className="mb-1 fw-bold">
                Middle Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter User Name"
                value={middleName}
                onChange={(e) => setMiddleName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-2 w-100">
              <Form.Label className="mb-1 fw-bold">
                Last Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter User Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Form.Group>
          </div>
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              Email <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              Phone <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Phone number"
              className={
                (phoneError && "border-danger text-danger") ||
                (phone && "border-success")
              }
              onChange={(e) => {
                setPhone(e.target.value);
                validatePhoneNumber(e.target.value);
              }}
              value={phone}
            />
            {phoneError && phone && (
              <Form.Text className="fs-10 m-0 text-danger">
                {phoneError}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              Autorities <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select onChange={(e) => setAutority(e.target.value)}>
              <option value="">Selecty Autorities</option>
              {accessMgmt?.map((item, index) => (
                <option key={index} value={item.name}>
                  {item.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              Address <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              rows={8}
              as="textarea"
              placeholder="Enter your address"
              className={address && "border-success"}
              style={{ height: "29px" }}
              onChange={(e) => setAddress(e.target.value)}
              value={address}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              Country Name<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter User Name"
              value={Country}
              onChange={(e) => setCountry(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              State Name<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter User Name"
              value={state}
              onChange={(e) => setState(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              City Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter User Name"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              Zip Code <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              required
              type="number"
              placeholder="Enter Postel Code"
              className={postalCode && "border-success"}
              onChange={(e) => setPostalCode(e.target.value)}
              value={postalCode}
            />
          </Form.Group>
          <Form.Group className="text-end">
            <button className="p-1 px-3 border rounded-1 text-white bg-color fs-13">
              <FaPlusSquare style={{ marginBottom: 2 }} />{" "}
              {!userUpdateId ? "Add" : "Update"} User
            </button>
          </Form.Group>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default AddUserForm;
