import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import ProjectTable from "./ProjectTable";
import StudyTable from "./StudyTable";

const ProjectStudyList = () => {
  return (
    <>
      <Container fluid>
        <Row>
          <Form.Group as={Col} md={6} className="p-0">
            <ProjectTable />
          </Form.Group>
          <Form.Group as={Col} md={6} className="p-0">
            <StudyTable />
          </Form.Group>
        </Row>
      </Container>
    </>
  );
};

export default ProjectStudyList;
