import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const Users = ({ graphData }) => {
  const userStatusCounts = [
    graphData?.activeUsers || 0,
    graphData?.inActiveUsers || 0,
  ];
  const totalLength = userStatusCounts.reduce((a, b) => a + b, 0);

  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "donut",
    },
    labels: ["Active Users", "Inactive Users"],
    colors: ["rgba(23, 43, 115, 1)", "rgba(23, 43, 115, 0.8)"],
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: (value) =>
          value !== undefined && value !== null ? value.toString() : "0",
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: false,
          },
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (value) => {
        if (totalLength === 0) {
          return "0";
        }

        const calculatedValue = Math.round((value / 100) * totalLength);
        return !isNaN(calculatedValue) ? calculatedValue.toString() : "0";
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
    },
  });

  return (
    <ReactApexChart
      options={chartOptions}
      series={userStatusCounts}
      type="donut"
      height={250}
    />
  );
};

export default Users;
