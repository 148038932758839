import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const Clients = ({ graphData = { activeClients: 0, inActiveClients: 0 } }) => {
  const clientStatusCounts = [
    graphData.activeClients || 0,
    graphData.inActiveClients || 0,
  ];

  const totalLength = clientStatusCounts.reduce((a, b) => a + b, 0);

  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "donut",
    },
    labels: ["Active Client", "Inactive Client"],
    colors: ["rgba(23, 43, 115, 1)", "rgba(23, 43, 115, 0.8)"],

    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: (value) =>
          value !== undefined && value !== null ? value.toString() : "0",
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: false,
          },
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (value) => {
        // Safeguard for totalLength being 0 to avoid division by 0
        if (totalLength === 0) {
          return "0";
        }

        const calculatedValue = Math.round((value / 100) * totalLength);
        return !isNaN(calculatedValue) ? calculatedValue.toString() : "0";
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
    },
  });

  // Check if clientStatusCounts are valid before rendering the chart
  if (
    clientStatusCounts.every((count) => typeof count === "number" && count >= 0)
  ) {
    return (
      <ReactApexChart
        options={chartOptions}
        series={clientStatusCounts}
        type="donut"
        height={250}
      />
    );
  } else {
    return <p>No data available for clients.</p>;
  }
};

export default Clients;
